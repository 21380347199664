import React from 'react';
import './Sansthan.css'

const Sansthan = () => {
  return (
    <>
   <header>
   <h1>श्री हरि बाबा बांध धाम (संभल)
</h1>
<p>
श्री हरि बाबा बांध धाम संभल जिले का एक प्रसिद्ध मंदिर है। यह मंदिर संभल जिले में गवां में स्थित है। यह मंदिर हरि बाबा को समर्पित है। हरि बाबा एक महान संत थे, जिन्होंने लोगों की भलाई के लिए अपना जीवन समर्पित किया। 

हरि बाबा जी का जन्म होशियारपुर पंजाब में हुआ था। उनके पिता का नाम श्री सरदार प्रताप सिंह था। महाराज श्री का विद्यार्थी जीवन गुरुदेव श्री सच्चिदानंद जी के चरणों में समर्पण हो चुका था। यहां पर आप लाहौर मेडिकल कॉलेज की एमबीबीएस की अंतिम वर्ष की पढ़ाई छोड़ कर पारिव्रजक अवस्था में विचार करते हुए, श्री भागीरथी किनारे क्षेत्र में आए। यह क्षेत्र आपको अति मनोरम लगा। यहां की बाढ़ पीड़ित जनता की दयनीय स्थिति देखकर आप द्रवित हो गया और श्री महाराज जी ने अपने उदार संकल्प से केवल 6 माह में 38 किलोमीटर लंबा विशाल बांध जन सहयोग से सन 1921 में निर्माण कराया। श्री बांध भगवान की कृपा से यह क्षेत्र हमेशा के लिए सुरक्षित हो गया। महाराज श्री का संपूर्ण जीवन वृत्त पवित्र विशाल ग्रंथ के रूप में आश्रम में उपलब्ध है। 

आपको यहां पर श्री हरि बाबा जी के दर्शन करने के लिए मिलते हैं। यहां पर बहुत सुंदर मंदिर बना हुआ है। यह पूरा मंदिर मार्बल से बना हुआ है। यहां पर बगीचा भी बना हुआ है। आप यहां पर आकर अपना बहुत अच्छा समय व्यतीत कर सकते हैं। यहां से थोड़ी ही दूरी पर आपको गंगा नदी का सुंदर दृश्य भी देखने के लिए मिल जाता है। 
</p>
   </header>
   <section>
    <h1 className='text-center'>Our Members</h1>
    <div className='tips t-r'>
       <h4>पुज्यपाद श्री श्री हरेकृष्ण ब्रह्मचारी जी महाराज -<span className='posst'>ट्रस्टाधिपति</span></h4>
    </div>
    <div className='tips t-g'>
       <h4>श्री बुद्धरंजन सिंह -<span className='posst'>अध्यक्ष</span></h4>
     <h5>Mob:- <a href='tel:+919719200132' className='text-white'>+919719200132</a></h5>
    </div>
    <div className='tips t-g'>
       <h4>श्री हृदेश कुमार सिंह -<span className='posst'>उपाध्यक्ष</span></h4>
    </div>
    <div className='tips t-b'>
       <h4>श्री रमाकांत पाठक -<span className='posst'>मंत्री</span></h4>
       <h5>Mob:-&nbsp;
         <a href='tel:+919758421009' className='text-white'>+919758421009</a>, &nbsp;
         <a href='tel:+919149067209' className='text-white'>+919149067209</a>
         </h5>
    </div>
    <div className='tips t-b'>
       <h4>श्री विश्वम्भर पाठक -<span className='posst'>उपमंत्री</span></h4>
       {/*  9758971309 */}
       <h5>Mob:- <a href='tel:+919758971309' className='text-white'>+919758971309</a></h5>
    </div>
    <div className='tips t-p'>
       <h4>श्री अभय कुमार सिंह -<span className='posst'>संपत्ति मैनेजर</span></h4>
    </div>
    <div className='tips t-p'>
       <h4>श्री अजय प्रताप सिंह -<span className='posst'>उपकोषाध्यक्ष</span></h4>
    </div>
    <div className='tips t-l'>
       <h4>श्री विशवमणि पाठक -<span className='posst'>सदस्य</span></h4>
    </div>

    <div className='tips t-l'>
       <h4> श्री तरुण प्रताप सिंह -<span className='posst'>सदस्य</span></h4>
    </div>
    <div className='tips t-l'>
       <h4> श्री रमाकांत शर्मा  -<span className='posst'>सदस्य</span></h4>
    </div>
    <div className='tips t-l'>
       <h4> श्री प्रवीन कुमार शर्मा   -<span className='posst'>सदस्य</span></h4>
    </div>
    <div className='tips t-l'>
       <h4> श्री आशुतोष प्रताप सिंह  -<span className='posst'>सदस्य</span></h4>
    </div>
    <div className='tips t-l'>
       <h4> श्री संतोष कुमार सिंह  -<span className='posst'>सदस्य</span></h4>
    </div>
    <div className='tips t-l'>
       <h4> श्री राघवेंद्र कुमार सिंह  -<span className='posst'>सदस्य</span></h4>
    </div>
    <div className='tips t-l'>
       <h4> श्री अमित कुमार सिंह  -<span className='posst'>सदस्य</span></h4>
    </div>
    <div className='tips t-l'>
       <h4> श्री योगेंद्र पाल सिंह तोमर -<span className='posst'>सदस्य</span></h4>
    </div>
    <div className='tips t-l'>
       <h4> श्री कृष्ण पाल सिंह तोमर  -<span className='posst'>सदस्य</span></h4>
    </div>
    <div className='tips t-l'>
       <h4> श्री प्रेमपाल सिंह यादव  -<span className='posst'>पदेन सदस्य</span></h4>
    </div>
    <div className='tips t-l'>
       <h4> श्री नवीन कुमार अग्रवाल  -<span className='posst'>सदस्य</span></h4>
    </div>
    <div className='tips t-l'>
       <h4> श्री मुकेश कुमार भगत जी  -<span className='posst'>सदस्य</span></h4>
    </div>
    <div className='tips t-l'>
       <h4> श्री शंकर सिंह  -<span className='posst'>सदस्य</span></h4>
    </div>
   </section>
    </>
  )
}

export default Sansthan;
